import {
  AccordionCardsV2,
  EAccordionV2RowHorizontalScroll,
  TAccordionCardV2,
  toImageProps,
} from '@front/shared/ds';
import { Section } from '@shared/master-types';
import React from 'react';

export type TAccordionsGridConnectedProps = Extract<
  NonNullable<Section['rows']>[number],
  { blockType: 'accordion-cards-v2' }
>;

const AccordionCardsV2Connected: React.FC<
  TAccordionsGridConnectedProps
> = props => {
  const { tabs, accordionItems, horizontalScroll, bulletsOptions } = props;

  if (!tabs?.length && !accordionItems?.length) {
    return null;
  }
  type TAccordionItems = NonNullable<(typeof tabs)[0]['accordionItems']>;
  const prepareCards = (cards: TAccordionItems = []): TAccordionCardV2[] => {
    return cards.map(({ image, icon, ...rest }) => ({
      ...rest,
      image: toImageProps(image),
      icon: toImageProps(icon),
      bulletsOptions: bulletsOptions,
    }));
  };
  const preparedTabs = tabs?.length
    ? tabs.map(tab => {
        return {
          title: tab?.title || '',
          cards: prepareCards(tab?.accordionItems),
        };
      })
    : accordionItems?.length
      ? [{ title: '', cards: prepareCards(accordionItems) }]
      : [];

  return (
    <AccordionCardsV2
      tabs={preparedTabs}
      horizontalScroll={horizontalScroll as EAccordionV2RowHorizontalScroll}
    />
  );
};

export default AccordionCardsV2Connected;
