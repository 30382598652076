import { cva } from 'cva';

export enum EAccordionV2IconPosition {
  Left = 'left',
  Right = 'right',
}

export enum EAccordionV2ImagePositionMobile {
  Top = 'top',
  Bottom = 'bottom',
}

export enum EAccordionV2ImagePositionDesktop {
  Top = 'top',
  Middle = 'middle',
  Bottom = 'bottom',
}

export const imageCVA = cva(
  'overflow-hidden prose-img:object-contain md:h-[10.375rem] md:min-w-[calc(100%_+_4px)] md:translate-x-[-2px] lg:h-[12.313rem] xl:hidden xl:rounded-3xl rtl:md:translate-x-[2px]',
  {
    variants: {
      position: {
        [EAccordionV2ImagePositionMobile.Top]: 'md:translate-y-[-2px]',
        [EAccordionV2ImagePositionMobile.Bottom]:
          'mt-auto md:translate-y-[2px]',
      },
      showBorderOnMobile: {
        true: 'min-w-[calc(100%_+_4px)] translate-x-[-2px] rtl:translate-x-[2px]',
        false: '',
      },
    },
    compoundVariants: [
      {
        position: EAccordionV2ImagePositionMobile.Top,
        showBorderOnMobile: true,
        className: 'translate-y-[-2px]',
      },
      {
        position: EAccordionV2ImagePositionMobile.Bottom,
        showBorderOnMobile: true,
        className: 'translate-y-[2px]',
      },
    ],
  },
);
