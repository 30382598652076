import React from 'react';

import {
  ESolidTabSize,
  ESolidTabTheme,
  SolidTab,
} from '../../../atoms/SolidTab';

type TAccordionCardsV2TabsProps = {
  tabs: { title?: string }[];
  activeIndex: number;
  onClick: (index: number) => void;
};

export const AccordionCardsV2Tabs: React.FC<TAccordionCardsV2TabsProps> = ({
  tabs,
  activeIndex,
  onClick,
}) => {
  if (!tabs || tabs.length < 2) {
    return null;
  }
  return (
    <div className='scrollbar-hidden overflow-x-auto'>
      <div className='mx-auto flex w-fit gap-y-4 px-4 pb-2'>
        {tabs.map((tab, i) => (
          <SolidTab
            key={i}
            onClick={() => onClick(i)}
            label={tab.title || ''}
            value={tab.title || ''}
            size={ESolidTabSize.Medium}
            theme={ESolidTabTheme.Ordinary}
            isActive={i === activeIndex}
          />
        ))}
      </div>
    </div>
  );
};
