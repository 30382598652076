import React, { useState } from 'react';

import { EAccordionV2RowHorizontalScroll } from './AccordionCardsV2.constants';
import { AccordionCardsV2Content } from './components/AccordionCardsV2Content';
import { AccordionCardsV2Tabs } from './components/AccordionCardsV2Tabs';
import { TAccordionCardV2 } from '../../atoms/AccordionCardV2';

export type TAccordionCardsV2TabsProps = {
  horizontalScroll?: EAccordionV2RowHorizontalScroll;
  tabs: {
    title: string;
    cards: TAccordionCardV2[];
  }[];
};

export const AccordionCardsV2: React.FC<TAccordionCardsV2TabsProps> = ({
  tabs = [],
  horizontalScroll,
}) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index: number): void => {
    setActiveTab(index);
  };
  const preparedTabs: { title: string }[] = tabs.map(({ title = '' }) => ({
    title,
  }));

  if (tabs.length === 0) {
    return null;
  }

  return (
    <div className='flex flex-col gap-10 overflow-hidden'>
      <AccordionCardsV2Tabs
        tabs={preparedTabs}
        activeIndex={activeTab}
        onClick={handleTabClick}
      />
      <AccordionCardsV2Content
        key={activeTab}
        {...tabs[activeTab]}
        horizontalScroll={horizontalScroll}
      />
    </div>
  );
};
